import { useMemo } from "react";
import { useInfiniteFetch } from "./helper/fetcher";
import { useToken } from "components/Provider/AuthProvider";
import getApiOptions from "./helper/getApiOptions";
import getQueryString from "libs/getQueryString";
import URLs from "data/urls";

export function useInfiniteUser(queryOptions) {
  const fetchURL = URLs.member;

  const { data, error, mutate, size, setSize, isValidating } = useInfiniteFetch((token, pageIndex, previousPageData) => {
    const queryString = getQueryString({ page: pageIndex + 1, ...queryOptions });

    return [`${fetchURL}${queryString}`, getApiOptions(token)];
  });

  const isLoadingInitialData = !data && !error;
  const isLoadingMore = isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === "undefined");

  const currentTotal = useMemo(() => {
    if (!data) return 0;
    return data.reduce((acc, cur) => acc + cur.list.length, 0);
  }, [data]);

  return { data, size, setSize, isLoading: isLoadingInitialData || isLoadingMore, currentTotal };
}

export function useInfiniteMedicalRecord(queryOptions) {
  const fetchURL = URLs.medical;

  const { data, error, mutate, size, setSize, isValidating } = useInfiniteFetch((token, pageIndex, previousPageData) => {
    const queryString = getQueryString({ page: pageIndex + 1, ...queryOptions });

    return [`${fetchURL}${queryString}`, getApiOptions(token)];
  });

  const isLoadingInitialData = !data && !error;
  const isLoadingMore = isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === "undefined");

  const currentTotal = useMemo(() => {
    if (!data) return 0;

    return data.reduce((acc, cur) => acc + cur.list.length, 0);
  }, [data]);

  return {
    data,
    size,
    setSize,
    isLoading: isLoadingInitialData || isLoadingMore,
    currentTotal,
    mutate,
  };
}
