import { useContext } from "react";

import { useGetFetch, postFetch } from "./helper/fetcher";
import getApiOptions from "./helper/getApiOptions";

import URLs from "data/urls";
import getQueryString from "libs/getQueryString";
import getTodayDate from "libs/date/getTodayDate";
import { useToken } from "components/Provider/AuthProvider";

//一般檢驗項目 (非機器)
export function useTestService() {
  const serviceMedicalURL = URLs.medical;
  const serviceStockMasterURL = URLs.stockMaster;

  const token = useToken();

  return {
    useAllTestItemCategory: (queryOptions, swrOptions, ableToFetch = true) => {
      const query = getQueryString(queryOptions);
      const fetchURL = ableToFetch ? `${serviceMedicalURL}/test-item${query}` : null;
      return useGetFetch(fetchURL, swrOptions);
    },
    useAllTestItem: (categoryId, swrOptions, ableToFetch = true) => {
      const fetchURL = categoryId && (ableToFetch ? `${serviceStockMasterURL}?categoryId=${categoryId}&limit=zero` : null);
      return useGetFetch(fetchURL, swrOptions);
    },
    useTestBom: (stockId, swrOptions, ableToFetch = true) => {
      const fetchURL = stockId && (ableToFetch ? `${serviceStockMasterURL}/bom?parent=${stockId}` : null);
      return useGetFetch(fetchURL, swrOptions);
    },
  };
}

//檢驗項目 (機器)
export function useTestMachineService() {
  const serviceMedicalURL = URLs.medical;
  const token = useToken();

  return {
    useAllTestEquipment: (queryOptions, swrOptions, ableToFetch = true) => {
      const query = getQueryString({ isStatus: 0, deleted: 0, ...queryOptions });
      const fetchURL = ableToFetch ? `${serviceMedicalURL}/test/equipment${query}` : null;
      return useGetFetch(fetchURL, swrOptions);
    },
    useAllTestReport: (queryOptions, swrOptions, ableToFetch = true) => {
      const query = getQueryString({ ...queryOptions });
      const fetchURL = ableToFetch ? `${serviceMedicalURL}/test-report${query}` : null;
      return useGetFetch(fetchURL, swrOptions);
    },
    useAllTestResult: (queryOptions, swrOptions, ableToFetch = true) => {
      const query = getQueryString({ ...queryOptions });
      const fetchURL = ableToFetch ? `${serviceMedicalURL}/test-result${query}` : null;
      return useGetFetch(fetchURL, swrOptions);
    },
    useMedicalRecordTestReport: (medicalRecordId, swrOptions, ableToFetch = true) => {
      const fetchURL = ableToFetch ? `${serviceMedicalURL}/${medicalRecordId}/test-report` : null;
      return useGetFetch(fetchURL, swrOptions);
    },

    useAllTestAssay: (queryOptions, swrOptions, ableToFetch = true) => {
      const fetchURL = ableToFetch ? `${serviceMedicalURL}/test-result/assay` : null;
      return useGetFetch(fetchURL, swrOptions);
    },

    postTestWithEquipment: async (medicalId, equipmentId) => {
      const fetchURL = `${serviceMedicalURL}/${medicalId}/test-report`;
      return await postFetch(fetchURL, {
        data: { machineryEquipmentConfigId: equipmentId },
        token,
      });
    },
  };
}
