import useSWR from 'swr';

import URLs from 'data/urls';
import { fetcher } from 'service/fetcher/index';
import queryConverter from 'service/helper/queryConverter';

export function useUserService() {
  const serviceURL = URLs.user;

  return {
    useAllUser({ query, swrOpt, isAble = true }: APIRequest.APIArgus<URLSearchParams> = {}) {
      const queryStr = queryConverter(query);
      const fetchURL = isAble ? `${serviceURL}?${queryStr}` : null;
      return useSWR<APIResponse.Pagination<API.User>>(fetchURL, fetcher, swrOpt);
    },
  };
}
