import useSWR from "swr";
import fetcher from "./helper/fetcher";

export const useCheckAuth = () => {
  const { data, error, mutate } = useSWR("/api/checkAuth", fetcher, {
    revalidateOnFocus: false,
    errorRetryCount: 2,
  });
  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  };
};
