/**
 * @deprecated - use URL.searchParams
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/URL/searchParams}
 */
export default function getQueryString(queryObj) {
  let queryString = "?";
  for (let [key, value] of Object.entries({ ...queryObj })) {
    if (value === "") continue;
    queryString += `${key}=${encodeURIComponent(value)}&`;
  }

  return queryString.slice(0, -1);
}
