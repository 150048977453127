import { useGetFetch, postFormDataFetch, deleteFetch } from "./helper/fetcher";

import URLs from "data/urls";
import { useToken } from "components/Provider/AuthProvider";

export function useUploadService() {
  const serviceURL = {
    //get & post(add)
    toPet: petId => `${URLs.pet}/${petId}/upload`,
    toPetDelete: imageId => `${URLs.pet}/upload/${imageId}`,

    toMedicalRecord: medicalRecordId => `${URLs.medical}/${medicalRecordId}/upload`,
    toMedicalRecordDelete: imageId => `${URLs.medical}/upload/${imageId}`,

    toMedicalRecordByPublic: medicalRecordId => `${URLs.mainURL}/public/medical/${medicalRecordId}/upload`,
  };
  const token = useToken();

  return {
    usePetImage: (petId, swrOptions, ableToFetch = true) => {
      const fetchURL = ableToFetch ? serviceURL.toPet(petId) : null;
      return useGetFetch(fetchURL, swrOptions);
    },
    addPetImage: async (petId, data) => {
      const { filesArray, filesList } = data;
      const formData = new FormData();
      filesArray.forEach(file => formData.append("fileInput", file));
      formData.append("fileDataList", JSON.stringify(filesList));

      return await postFormDataFetch(serviceURL.toPet(petId), { formData, token });
    },

    useMedicalRecordFile: (id, swrOptions, ableToFetch = true) => {
      const fetchURL = id && (ableToFetch ? serviceURL.toMedicalRecord(id) : null);
      return useGetFetch(fetchURL, swrOptions);
    },

    addMedicalRecordFile: async (id, data, { pub, hash } = {}) => {
      const { filesArray, filesList } = data;
      const formData = new FormData();
      filesArray.forEach(file => formData.append("fileInput", file.file));
      formData.append("fileDataList", JSON.stringify(filesList));

      const targetURL = pub ? serviceURL.toMedicalRecordByPublic(id) : serviceURL.toMedicalRecord(id);

      return await postFormDataFetch(targetURL, { formData, token, hash });
    },

    deleteMedicalRecordFile: async fileId => {
      return await deleteFetch(serviceURL.toMedicalRecordDelete(fileId), { token });
    },
  };
}
