export default function getApiOptions(token, apiOptions = {}, isAnonymous = false) {
  return {
    headers: {
      Authorization: token?.includes('Bearer') ? token : `Bearer ${token}`,
      'Content-Type': 'application/json',
      ...(isAnonymous && { 'x-api-type': 'user_incognito' }),
    },
    ...apiOptions,
  };
}
