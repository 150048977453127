import useSWR, { Key } from 'swr';
import useSWRMutation from 'swr/mutation';

import URLs from 'data/urls';
import { fetcher, sendRequest } from 'service/fetcher/index';
import queryConverter from 'service/helper/queryConverter';

export function useCageService() {
  const serviceURL = URLs.hospitalize;
  const zoneServiceURL = `${serviceURL}/zone`;
  const cageServiceURL = `${serviceURL}/zone/pet-cage`;
  const cageStyleServiceURL = `${serviceURL}/zone/pet-cage/style`;

  return {
    useAllZone({ query, swrOpt, isAble = true }: APIRequest.APIArgus<URLSearchParams> = {}) {
      const queryStr = queryConverter(query);
      const fetchURL = isAble ? `${zoneServiceURL}?${queryStr}` : null;
      return useSWR<APIResponse.List<API.Zone>>(fetchURL, fetcher, swrOpt);
    },

    useCreateZone<U extends Omit<APIRequest.ZoneReq, 'id'>>() {
      return useSWRMutation<APIResponse.SuccessRes, any, Key, U>(zoneServiceURL, sendRequest<U>('POST'));
    },

    useEditZone<U extends APIRequest.ZoneReq>() {
      return useSWRMutation<APIResponse.SuccessRes, any, Key, U>(zoneServiceURL, sendRequest<U>('PUT'));
    },

    useAllCage({ query, swrOpt, isAble = true }: APIRequest.APIArgus<URLSearchParams> = {}) {
      const queryStr = queryConverter(query);
      const fetchURL = isAble ? `${cageServiceURL}?${queryStr}` : null;
      return useSWR<APIResponse.List<API.Cage>>(fetchURL, fetcher, swrOpt);
    },

    useCreateCage<U extends Partial<APIRequest.CageReq>>() {
      return useSWRMutation<APIResponse.SuccessRes, any, Key, U>(cageServiceURL, sendRequest<U>('POST'));
    },

    useEditCage<U extends Partial<APIRequest.CageReq>>() {
      return useSWRMutation<APIResponse.SuccessRes, any, Key, U>(cageServiceURL, sendRequest<U>('PUT'));
    },

    useAllCageStyle({ query, swrOpt, isAble = true }: APIRequest.APIArgus<URLSearchParams> = {}) {
      const queryStr = queryConverter(query);
      const fetchURL = isAble ? `${cageStyleServiceURL}?${queryStr}` : null;
      return useSWR<APIResponse.List<API.CageStyle>>(fetchURL, fetcher, swrOpt);
    },

    useCreateCageStyle<U extends Omit<APIRequest.CageStyleReq, 'id'>>() {
      return useSWRMutation<APIResponse.SuccessRes, any, Key, U>(cageStyleServiceURL, sendRequest<U>('POST'));
    },

    useEditCageStyle<U extends APIRequest.CageStyleReq>() {
      return useSWRMutation<APIResponse.SuccessRes, any, Key, U>(cageStyleServiceURL, sendRequest<U>('PUT'));
    },

    useDeleteCageStyle<U extends APIRequest.SpecificReq>() {
      return useSWRMutation<APIResponse.SuccessRes, any, Key, U>(cageStyleServiceURL, sendRequest<U>('DELETE'));
    },

    // useUpdateMultipleApprove<U extends APIRequest.ApproveMultiReq>() {
    //   return useSWRMutation<APIResponse.SuccessRes, any, Key, U>(serviceURL, sendRequest<U>('PUT'));
    // },
  };
}
