import useSWR from "swr";

import URLs from "data/urls";
import { fetcher } from "service/fetcher/index";
import queryConverter from "service/helper/queryConverter";

export function usePetService() {
  const serviceURL = URLs.pet;

  return {
    useAllPet({ query, swrOpt, isAble = true }: APIRequest.APIArgus<URLSearchParams> = {}) {
      const queryStr = queryConverter(query);
      const fetchURL = isAble ? `${serviceURL}?${queryStr}` : null;
      return useSWR<APIResponse.Pagination<API.Pet>>(fetchURL, fetcher, swrOpt);
    },
    useOnePet({ query, swrOpt, isAble = true }: APIRequest.APIArgus<string> = {}) {
      const queryStr = queryConverter(query);
      const fetchURL = isAble ? `${serviceURL}/${queryStr}` : null;
      return useSWR<API.Pet>(fetchURL, fetcher, swrOpt);
    },
  };
}
