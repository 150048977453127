import useSWR from "swr";
import { fetcher } from "./fetcher";
import URLs from "data/urls";

export const useTotpService = () => {
  return {
    useVerifyTotpForMember: ({ totp, custNo }, swrOptions) => {
      const serviceURL = `${URLs.member}/totp/verify?totp=${totp}&custNo=${custNo}`;

      return useSWR([serviceURL], fetcher, swrOptions);
    },
  };
};
