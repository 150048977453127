import useSWR, { Key } from 'swr';
import useSWRMutation from 'swr/mutation';

import URLs from 'data/urls';
import { fetcher, sendRequest } from 'service/fetcher/index';
import queryConverter from 'service/helper/queryConverter';

export function useHumanResourcesLeaveService() {
  const mainURL = URLs.mainURL;
  const serviceURL = URLs.leave;

  return {
    useAllLeave({ query, swrOpt, isAble = true }: APIRequest.APIArgus<URLSearchParams> = {}) {
      const queryStr = queryConverter(query);
      const fetchURL = isAble ? `${serviceURL}?${queryStr}` : null;
      return useSWR<APIResponse.Pagination<API.Leave>>(fetchURL, fetcher, swrOpt);
    },
    useOneLeave({ query, swrOpt, isAble = true }: APIRequest.APIArgus<string> = {}) {
      const queryStr = queryConverter(query);
      const fetchURL = isAble ? `${serviceURL}/${queryStr}` : null;
      return useSWR<API.Leave>(fetchURL, fetcher, swrOpt);
    },

    useCreateLeave<U extends Omit<APIRequest.LeaveReq, 'id'>>() {
      return useSWRMutation<APIResponse.SuccessRes, any, Key, U>(serviceURL, sendRequest<U>('POST'));
    },
    useUpdateLeave<U extends APIRequest.LeaveReq>() {
      return useSWRMutation<APIResponse.SuccessRes, any, Key, U>(serviceURL, sendRequest<U>('PUT'));
    },
    useDeleteLeave() {
      return useSWRMutation(serviceURL, sendRequest<APIRequest.SpecificReq>('DELETE'));
    },

    //TODO: type
    //@ts-ignore
    useLeaveType({ query, swrOpt, isAble = true } = {}) {
      const queryStr = queryConverter(query);
      const fetchURL = isAble ? `${mainURL}/api/leaveType?${queryStr}` : null;
      return useSWR(fetchURL, fetcher, swrOpt);
    },
  };
}
