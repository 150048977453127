import { createContext, useContext, useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useCheckAuth } from 'service/auth';
import Link from 'next/link';
import SWRProvider from './SWRProvider';

const initState = {
  token: '',
  user: null,
};
const AuthContext = createContext(initState);

function AlertDialog() {
  return (
    <Dialog open={true} maxWidth="sm" fullWidth>
      <DialogTitle>驗證已過期</DialogTitle>
      <DialogContent>請重新登入</DialogContent>
      <DialogActions>
        <Link href="/login">
          <Button>重新登入</Button>
        </Link>
      </DialogActions>
    </Dialog>
  );
}

export const AuthProvider = ({ children }) => {
  const [state, setState] = useState();
  const [error, setError] = useState(false);
  const { data: res, isLoading, isError, mutate } = useCheckAuth();

  useEffect(() => {
    if (!res) return;
    async function init() {
      try {
        const data = await res.clone().json();
        setState(data);
        setError(false);
      } catch (err) {
        setError(true);
      }
    }

    if (res.ok) {
      init();
    } else {
      setError(true);
    }
  }, [res]);

  return (
    <AuthContext.Provider value={{ ...state, mutate }}>
      <SWRProvider>{state && children}</SWRProvider>
      {error && <AlertDialog />}
    </AuthContext.Provider>
  );
};

export const useMe = () => useContext(AuthContext);
export const useToken = () => useContext(AuthContext).token;
