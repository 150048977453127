import { createContext, useReducer } from 'react';
/**
 * @deprecated
 */
export const AnonymousAuthContext = createContext(null);
/**
 * @deprecated
 */
const reducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      return { isAuth: true, data: 'Bearer ' + action.data };
    case 'LOGOUT':
      return { isAuth: false, data: '' };
    default:
      return { isAuth: false, data: '' };
  }
};
/**
 * @deprecated
 */
export const AnonymousAuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, null);

  return <AnonymousAuthContext.Provider value={{ state, dispatch }}>{children}</AnonymousAuthContext.Provider>;
};
