import { useContext } from "react";
import { AnonymousAuthContext } from "components/context/AnonymousAuthContext";
import { useGetFetch } from "./helper/fetcher";
import getApiOptions from "./helper/getApiOptions";

import URLs from "data/urls";
import { useToken } from "components/Provider/AuthProvider";

export const useHospDataService = (isAnonymous = false) => {
  const serviceURL = URLs.data;

  const anonymousState = useContext(AnonymousAuthContext);
  const userToken = useToken();
  const token = isAnonymous ? anonymousState?.state?.data : userToken;
  const apiOptions = getApiOptions(token, null, isAnonymous);

  return {
    useConfig: (queryOptions, swrOptions, ableToFetch = true) => {
      const fetchURL = ableToFetch ? [`${serviceURL}/config`, apiOptions] : null;
      return useGetFetch(fetchURL, swrOptions);
    },
    useHospLogo: (queryOptions, swrOptions, ableToFetch = true) => {
      const fetchURL = ableToFetch ? [`${serviceURL}/image`, apiOptions] : null;
      return useGetFetch(fetchURL, swrOptions);
    },
  };
};
