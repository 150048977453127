import useSWR from "swr";
import useSWRInfinite from "swr/infinite";
import { toast } from "react-toastify";

const anonymousValue = { "x-api-type": "user_incognito " };

const fetcher = args =>
  fetch(...args)
    .then(res => {
      if (res.status !== 200) {
        throw new Error("一般請求失敗");
      }
      return res.json();
    })
    .catch(err => {
      toast.error(err.message);
      throw new Error(err.message);
    });

// const fetcher = (...args) =>
//   new Promise((resolve, reject) => {
//     setTimeout(() => {
//       resolve(
//         fetch(...args)
//           .then(res => {
//             if (res.status !== 200) {
//               throw new Error("一般請求失敗")
//             }
//             return res.json()
//           })
//           .catch(err => toast.error(err.message))
//       )
//     }, 5000)
//   })

export const useGetFetch = (url, options) => {
  const { data, error, mutate } = useSWR(url, fetcher, {
    revalidateOnFocus: false,
    ...options,
  });

  return { data, error, isLoading: !data && !error, isError: error, mutate };
};

export const useInfiniteFetch = getKey => {
  return useSWRInfinite(getKey, fetcher, { revalidateFirstPage: false, revalidateOnFocus: true });
};

export const postFormDataFetch = async (url, payload) => {
  const res = await fetch(url, {
    method: "POST",
    ...(payload.formData && { body: payload.formData }),
    headers: {
      ...(payload.token && {
        Authorization: payload.token,
      }),
      ...(payload.hash && payload.hash),
    },
  });
  const data = await res.json();
  if (res.status !== 200) {
    throw new Error(JSON.stringify(data.error));
  }

  return data;
};

export const getFetch = async (url, payload, isAnonymous) => {
  const res = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...(payload.token && {
        Authorization: payload.token,
      }),
      ...(isAnonymous && anonymousValue),
    },
  });
  const data = await res.json();
  if (res.status !== 200) {
    throw new Error(JSON.stringify(data.error));
  }

  return data;
};

export const postFetch = async (url, payload, isAnonymous) => {
  const res = await fetch(url, {
    method: "POST",
    ...(payload.data && { body: JSON.stringify(payload.data) }),
    headers: {
      "Content-Type": "application/json",
      ...(payload.token && {
        Authorization: payload.token,
      }),
      ...(isAnonymous && anonymousValue),
    },
  });
  const data = await res.json();
  if (res.status === 409) {
    return res;
  }

  if (res.status !== 200) {
    throw new Error(JSON.stringify(data.error));
  }

  return data;
};

export const putFetch = async (url, payload, isAnonymous) => {
  const res = await fetch(url, {
    method: "PUT",
    ...(payload.data && { body: JSON.stringify(payload.data) }),
    headers: {
      "Content-Type": "application/json",
      ...(payload.token && {
        Authorization: payload.token,
      }),
      ...(isAnonymous && anonymousValue),
    },
  });

  const data = await res.clone().json();
  if (res.status === 409) {
    return res;
  }
  if (res.status !== 200) {
    throw new Error(JSON.stringify(data.error));
  }

  return res;
};

export const deleteFetch = async (url, payload, isAnonymous) => {
  const res = await fetch(url, {
    method: "DELETE",
    ...(payload.data && { body: JSON.stringify(payload.data) }),
    headers: {
      "Content-Type": "application/json",
      ...(payload.token && {
        Authorization: payload.token,
      }),
      ...(isAnonymous && anonymousValue),
    },
  });
  const data = await res.json();
  if (res.status !== 200) {
    throw new Error(JSON.stringify(data.error));
  }

  return data;
};

export const patchFetch = async (url, payload, isAnonymous) => {
  const res = await fetch(url, {
    method: "PATCH",
    ...(payload.data && { body: JSON.stringify(payload.data) }),
    headers: {
      "Content-Type": "application/json",
      ...(payload.token && {
        Authorization: payload.token,
      }),
      ...(isAnonymous && anonymousValue),
    },
  });
  const data = await res.json();
  if (res.status === 409) {
    return res;
  }
  if (res.status !== 200) {
    throw new Error(JSON.stringify(data.error));
  }

  return data;
};

export default fetch;
