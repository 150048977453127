import { SWRConfig } from 'swr';
import { useToken } from './AuthProvider';
import getApiOptions from 'service/helper/getApiOptions';
import { isArray, isFunction } from 'lodash';

const getToken = useSWRNext => {
  return (key, fetcher, config) => {
    // Before hook runs...
    const token = useToken();
    const apiOptions = getApiOptions(token);

    //TODO: 過渡用，等待移除
    let temp = null;
    //key = [url, opt]
    if (isArray(key)) {
      temp = key;
    }
    //swr infinite
    if (isFunction(key)) {
      temp = key.bind(null, token);
    }

    let keyIn = temp ? temp : key && token ? [key, apiOptions] : null;
    // Handle the next middleware, or the `useSWR` hook if this is the last one.
    const swr = useSWRNext(keyIn, fetcher, config);
    // After hook runs...
    return swr;
  };
};

export default function SWRProvider(props) {
  return <SWRConfig value={{ use: [getToken], revalidateOnFocus: false }} {...props} />;
}
