import useSWR, { Key } from "swr";
import useSWRMutation from "swr/mutation";

import URLs from "data/urls";
import { fetcher, sendRequest } from "service/fetcher/index";
import queryConverter from "service/helper/queryConverter";

export function useHospitalizeService() {
  const serviceURL = URLs.hospitalize;

  return {
    useAllHospitalize({ query, swrOpt, isAble = true }: APIRequest.APIArgus<URLSearchParams> = {}) {
      const queryStr = queryConverter(query);
      const fetchURL = isAble ? `${serviceURL}?${queryStr}` : null;
      return useSWR<APIResponse.Pagination<API.Hospitalize>>(fetchURL, fetcher, swrOpt);
    },

    useOneHospitalize({ query, swrOpt, isAble = true }: APIRequest.APIArgus<string> = {}) {
      const queryStr = queryConverter(query);
      const fetchURL = isAble ? `${serviceURL}/${queryStr}` : null;
      return useSWR<API.Hospitalize>(fetchURL, fetcher, swrOpt);
    },

    useCreateHospitalize<
      U extends Pick<APIRequest.HospitalizeReq, "petCageDrvetId" | "petIdentityId" | "hospitalizeStart" | "hospitalizeEnd"> &
        Partial<Pick<APIRequest.HospitalizeReq, "employeeId" | "mainDescription" | "subDescription">>,
    >() {
      return useSWRMutation(serviceURL, sendRequest<U>("POST"));
    },

    useEditHospitalize<U extends Partial<Omit<APIRequest.HospitalizeReq, "isHospitalized" | "petIdentityId">>>() {
      return useSWRMutation<APIResponse.SuccessRes, any, Key, U>(serviceURL, sendRequest<U>("PUT"));
    },

    useDeleteHospitalize<U extends APIRequest.SpecificReq>() {
      return useSWRMutation(serviceURL, sendRequest<U>("DELETE"));
    },

    useUpdateHospitalizeStayStatus<U extends Required<Pick<APIRequest.HospitalizeReq, "id" | "isHospitalized">>>() {
      return useSWRMutation(serviceURL, sendRequest<U>("PUT"));
    },
  };
}

export function useHospitalizePhysicalService() {
  const serviceURL = `${URLs.hospitalize}/physical`;

  return {
    useAllHospitalizePhysical({ query, swrOpt, isAble = true }: APIRequest.APIArgus<URLSearchParams> = {}) {
      const queryStr = queryConverter(query);
      const fetchURL = isAble ? `${serviceURL}?${queryStr}` : null;
      return useSWR<APIResponse.Pagination<API.PhysicalRecordSchedule>>(fetchURL, fetcher, swrOpt);
    },
    useCreateHospitalizePhysical<U extends Pick<APIRequest.HospitalizePhysicalReq, "hospitalizeId" | "scheduleStart" | "scheduleType">>() {
      return useSWRMutation<APIResponse.SuccessRes, any, Key, U>(serviceURL, sendRequest<U>("POST"));
    },
    useUpdateHospitalizePhysical<U extends APIRequest.HospitalizePhysicalFormReq>() {
      return useSWRMutation(serviceURL, sendRequest<U>("PUT"));
    },
    useDeleteHospitalizePhysical<U extends APIRequest.SpecificReq>() {
      return useSWRMutation(serviceURL, sendRequest<U>("DELETE"));
    },

    useOneHospitalizePhysical({ query, swrOpt, isAble = true }: APIRequest.APIArgus<string> = {}) {
      const queryStr = queryConverter(query);
      const fetchURL = isAble ? `${serviceURL}/${queryStr}` : null;
      return useSWR<API.PhysicalRecordSchedule>(fetchURL, fetcher, swrOpt);
    },
  };
}

export function useHospitalizeDrugService() {
  const servicePackURL = `${URLs.hospitalize}/drug-pack`;
  const serviceRecordURL = `${URLs.hospitalize}/drug-record`;

  return {
    useAllHospitalizeDrugRecord({ query, swrOpt, isAble = true }: APIRequest.APIArgus<URLSearchParams> = {}) {
      const queryStr = queryConverter(query);
      const fetchURL = isAble ? `${serviceRecordURL}?${queryStr}` : null;
      return useSWR<APIResponse.Pagination<API.HospitalizeRecordSchedule>>(fetchURL, fetcher, swrOpt);
    },
    useUpdateHospitalizeDrugRecord<U extends APIRequest.UpdateHospitalizeDrugRecord>() {
      return useSWRMutation(serviceRecordURL, sendRequest<U>("PUT"));
    },
    useAllHospitalizeDrugPack({ query, swrOpt, isAble = true }: APIRequest.APIArgus<URLSearchParams> = {}) {
      const queryStr = queryConverter(query);
      const fetchURL = isAble ? `${servicePackURL}?${queryStr}` : null;
      return useSWR<APIResponse.Pagination<API.HospitalizeDrugsPacks>>(fetchURL, fetcher, swrOpt);
    },
    useCreateHospitalizeDrugPack<U extends APIRequest.HospitalizeDrugPack>() {
      return useSWRMutation(servicePackURL, sendRequest<U>("POST"));
    },
    useUpdateHospitalizeDrugPack<U extends APIRequest.UpdateHospitalizeDrugPack>() {
      return useSWRMutation(servicePackURL, sendRequest<U>("PUT"));
    },
  };
}
