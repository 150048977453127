import { useContext } from "react";
import { AnonymousAuthContext } from "components/context/AnonymousAuthContext";
import { useGetFetch, postFetch, putFetch, deleteFetch } from "./helper/fetcher";
import getApiOptions from "./helper/getApiOptions";

import URLs from "data/urls";
import getQueryString from "libs/getQueryString";
import { useMe, useToken } from "components/Provider/AuthProvider";

/**
 * @deprecated
 */
export function useOldPetService(isAnonymous = false) {
  const serviceURL = URLs.pet;
  const anonymousState = useContext(AnonymousAuthContext);
  const user = useMe();
  const token = isAnonymous ? anonymousState?.state?.data : user.token;
  const apiOptions = getApiOptions(token, null, isAnonymous);

  const userId = user.userId;

  return {
    useAllPet: (queryOptions, swrOptions, ableToFetch = true) => {
      const query = getQueryString({ ...queryOptions });
      const fetchURL = ableToFetch ? [`${serviceURL}${query}`, apiOptions] : null;
      return useGetFetch(fetchURL, swrOptions);
    },
    useOnePet: (petId, swrOptions, ableToFetch = true) => {
      const fetchURL = petId && (ableToFetch ? [`${serviceURL}/${petId}`, apiOptions] : null);
      return useGetFetch(fetchURL, swrOptions);
    },
    addPet: async data => {
      return await postFetch(serviceURL, { data, token });
    },
    updatePet: async (petId, data) => {
      return await putFetch(`${serviceURL}/${petId}`, {
        data: { ...data, modifyUserId: userId },
        token,
      });
    },

    //opts
    usePetGenderOpts: (queryOptions = {}, swrOptions, ableToFetch = true) => {
      const query = getQueryString({ ...queryOptions });
      const fetchURL = ableToFetch ? [`${serviceURL}/gender${query}`, apiOptions] : null;
      return useGetFetch(fetchURL, swrOptions);
    },
    usePetTypeOpts: (queryOptions = {}, swrOptions, ableToFetch = true) => {
      const query = getQueryString({ deleted: 0, ...queryOptions });
      const fetchURL = ableToFetch ? [`${serviceURL}/type${query}`, apiOptions] : null;
      return useGetFetch(fetchURL, swrOptions);
    },
    usePetVarietyOpts: (queryOptions = {}, swrOptions, ableToFetch = true) => {
      const query = getQueryString({ limit: "zero", deleted: 0, ...queryOptions });
      const fetchURL = ableToFetch ? [`${serviceURL}/variety${query}`, apiOptions] : null;
      return useGetFetch(fetchURL, swrOptions);
    },
    usePetBloodOpts: (queryOptions = {}, swrOptions, ableToFetch = true) => {
      const query = getQueryString({ deleted: 0, ...queryOptions });
      const fetchURL = ableToFetch ? [`${serviceURL}/blood${query}`, apiOptions] : null;
      return useGetFetch(fetchURL, swrOptions);
    },

    registerDeath: async petId => {
      return await putFetch(`${serviceURL}/${petId}`, {
        data: { killUserId: userId },
        token,
      });
    },

    deletePet: async petId => {
      return await deleteFetch(`${serviceURL}/${petId}`, {
        token,
      });
    },
  };
}
