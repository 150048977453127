import { useContext } from "react";
import { AnonymousAuthContext } from "components/context/AnonymousAuthContext";
import { useGetFetch, postFetch, putFetch, deleteFetch } from "./helper/fetcher";
import getApiOptions from "./helper/getApiOptions";

import URLs from "data/urls";
import getQueryString from "libs/getQueryString";
import { useToken } from "components/Provider/AuthProvider";

// TODO: fix the anonymous problem
export function useMemberService(isAnonymous = false) {
  const serviceURL = URLs.member;
  const anonymousState = useContext(AnonymousAuthContext);
  const userToken = useToken();
  const token = isAnonymous ? anonymousState?.state?.data : userToken;
  const apiOptions = getApiOptions(token, null, isAnonymous);

  return {
    useAllMember: (queryOptions, swrOptions, ableToFetch = true) => {
      const query = getQueryString({ ...queryOptions });
      const fetchURL = ableToFetch ? [`${serviceURL}${query}`, apiOptions] : null;
      return useGetFetch(fetchURL, swrOptions);
    },
    useOneMember: (memberId, swrOptions, ableToFetch = true) => {
      const fetchURL = memberId && (ableToFetch ? `${serviceURL}/${memberId}` : null);
      return useGetFetch(fetchURL, swrOptions);
    },

    //新增會員也包含可新增寵物
    addMember: async data => {
      return await postFetch(serviceURL, { data, token }, isAnonymous);
    },
    updateMember: async (memberId, data) => {
      return await putFetch(`${serviceURL}/${memberId}`, { data, token });
    },
    deleteMember: async memberId => {
      return await deleteFetch(`${serviceURL}/${memberId}`, { token });
    },

    // opts
    useMemberGenderOpts: (queryOptions = {}, swrOptions, ableToFetch = true) => {
      const query = getQueryString({ deleted: 0, ...queryOptions });
      const fetchURL = ableToFetch ? [`${serviceURL}/gender${query}`, apiOptions] : null;
      return useGetFetch(fetchURL, swrOptions);
    },
  };
}
