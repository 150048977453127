import { useGetFetch, postFetch, getFetch, putFetch, patchFetch, deleteFetch } from "./helper/fetcher";

import URLs from "data/urls";
import getQueryString from "libs/getQueryString";

import { useToken } from "components/Provider/AuthProvider";

export function useMedicalRecordService() {
  const serviceURL = URLs.medical;
  const token = useToken();

  return {
    useAllMedicalRecord: (queryOptions, swrOptions, ableToFetch = true) => {
      const query = getQueryString(queryOptions);
      const fetchURL = ableToFetch ? `${serviceURL}${query}` : null;
      return useGetFetch(fetchURL, swrOptions);
    },

    useOneMedicalRecord: (id, swrOptions, ableToFetch = true) => {
      const fetchURL = id && (ableToFetch ? `${serviceURL}/${id}` : null);
      return useGetFetch(fetchURL, swrOptions);
    },

    //一般fetch without swr
    getOneMedicalRecord: async id => {
      return await getFetch(`${serviceURL}/${id}`, { token });
    },

    //新增病歷
    initMedicalRecord: async data => {
      return await postFetch(`${serviceURL}/init`, { data, token });
    },

    //更新
    updateMedicalRecord: async (id, data) => {
      return await putFetch(`${serviceURL}/${id}`, { data, token });
    },

    deleteMedicalRecord: async id => {
      return await deleteFetch(`${serviceURL}/${id}`, { token });
    },

    //特殊更新，isPaid 的病歷只能更新特定內容，於這一段整理送出
    patchUpdateMedicalRecord: async (id, data) => {
      const pickedData = {
        version: data.version,
        description: data.description,
        memberReserveId: data.memberReserveId,
        drugDescription: data.drugDescription,
        customerDescription: data.customerDescription,
      };

      return await patchFetch(`${serviceURL}/${id}`, {
        data: pickedData,
        token,
      });
    },

    //完診
    completeMedicalRecord: async medicalRecordId => {
      return await postFetch(`${serviceURL}/${medicalRecordId}/complete`, {
        token,
      });
    },

    usePetWeightHistory: (petId, swrOptions, ableToFetch = true) => {
      const fetchURL = petId && (ableToFetch ? `${serviceURL}/weight-history/${petId}?limit=0&sort=tranDate desc` : null);
      return useGetFetch(fetchURL, swrOptions);
    },
  };
}
