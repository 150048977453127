import { useGetFetch } from "./helper/fetcher";

import URLs from "data/urls";
import getQueryString from "libs/getQueryString";
import { format } from "date-fns";
import { useToken } from "components/Provider/AuthProvider";

export function usePharmacyService() {
  const serviceURL = URLs.pharmacy;
  const token = useToken();

  return {
    useAllPharmacy: (queryOptions, swrOptions, ableToFetch = true) => {
      const defaultToday = format(new Date(), "yyyy-MM-dd");

      const query = getQueryString({
        //default today
        date: defaultToday,
        ...queryOptions,
      });
      const fetchURL = ableToFetch ? `${serviceURL}${query}` : null;
      return useGetFetch(fetchURL, swrOptions);
    },
  };
}
