import { useGetFetch, postFetch, putFetch, deleteFetch, patchFetch } from "./helper/fetcher";
import getApiOptions from "./helper/getApiOptions";

import URLs from "data/urls";
import getQueryString from "libs/getQueryString";
import { format } from "date-fns";
import { useToken } from "components/Provider/AuthProvider";

export function useReservationService() {
  const serviceURL = URLs.reservation;
  const token = useToken();
  const apiOptions = getApiOptions(token);

  return {
    useAllReservation: (queryOptions, swrOptions, ableToFetch = true) => {
      const defaultToday = format(new Date(), "yyyy-MM-dd");

      const query = getQueryString({
        //default today
        date: defaultToday,
        ...queryOptions,
      });
      const fetchURL = ableToFetch ? `${serviceURL}${query}` : null;
      return useGetFetch(fetchURL, swrOptions);
    },
    useOneReservation: (reservationId, swrOptions, ableToFetch = true) => {
      const fetchURL = ableToFetch ? `${serviceURL}/${reservationId}` : null;
      return useGetFetch(fetchURL, swrOptions);
    },

    addReservation: async data => {
      return await postFetch(serviceURL, { data, token });
    },
    checkInReservation: async reservationId => {
      return await postFetch(`${serviceURL}/${reservationId}/checkin`, {
        token,
      });
    },
    cancelReservation: async reservationId => {
      return await putFetch(`${serviceURL}/${reservationId}/cancel`, { token });
    },
    deleteReservation: async reservationId => {
      return await deleteFetch(`${serviceURL}/${reservationId}`, { token });
    },
    editReservation: async (reservationId, data) => {
      return await putFetch(`${serviceURL}/${reservationId}`, { data, token });
    },
    onlyDescriptionEditReservation: async (reservationId, data) => {
      return await patchFetch(`${serviceURL}/${reservationId}`, { data, token });
    },
  };
}

export function useManipulateType() {
  const serviceURL = URLs.manipulateType;
  const token = useToken();
  const apiOptions = getApiOptions(token);

  return {
    useAllManipulateType: (q, s, a = true) => {
      const query = getQueryString({
        isLook: 0,
        deleted: 0,
        ...q,
      });
      const fetchURL = a ? `${serviceURL}${query}` : null;
      return useGetFetch(fetchURL, s);
    },
  };
}
