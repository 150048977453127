import { useGetFetch, getFetch } from "./helper/fetcher";
import getApiOptions from "./helper/getApiOptions";

import URLs from "data/urls";
import getQueryString from "libs/getQueryString";
import { useToken } from "components/Provider/AuthProvider";

export function useStockMasterService() {
  const serviceURL = URLs.stockMaster;
  const token = useToken();
  const apiOptions = getApiOptions(token);

  return {
    useAllStockMaster: (queryOptions, swrOptions, ableToFetch = true) => {
      const query = getQueryString({ ...queryOptions });
      const fetchURL = ableToFetch ? `${serviceURL}${query}` : null;
      return useGetFetch(fetchURL, swrOptions);
    },
    useOneStockMaster: (id, swrOptions, ableToFetch = true) => {
      const fetchURL = id && (ableToFetch ? `${serviceURL}/${id}` : null);
      return useGetFetch(fetchURL, swrOptions);
    },

    //一般fetch without swr
    getOneStockMasterBudget: async id => {
      return await getFetch(`${serviceURL}/${encodeURIComponent(id)}/budget`, { token });
    },
  };
}
