import useSWR, { Key } from 'swr';
import useSWRMutation from 'swr/mutation';

import URLs from 'data/urls';
import { fetcher, sendRequest } from 'service/fetcher/index';
import queryConverter from 'service/helper/queryConverter';

export function useHumanResourcesOvertimeService() {
  const serviceURL = URLs.overtime;

  return {
    useAllOvertime({ query, swrOpt, isAble = true }: APIRequest.APIArgus<URLSearchParams> = {}) {
      const queryStr = queryConverter(query);
      const fetchURL = isAble ? `${serviceURL}?${queryStr}` : null;
      return useSWR<APIResponse.Pagination<API.Overtime>>(fetchURL, fetcher, swrOpt);
    },
    useOneOvertime({ query, swrOpt, isAble = true }: APIRequest.APIArgus<string> = {}) {
      const queryStr = queryConverter(query);
      const fetchURL = isAble ? `${serviceURL}/${queryStr}` : null;
      return useSWR<API.Overtime>(fetchURL, fetcher, swrOpt);
    },

    useCreateOvertime<U extends Omit<APIRequest.OvertimeReq, 'id'>>() {
      return useSWRMutation<APIResponse.SuccessRes, any, Key, U>(serviceURL, sendRequest<U>('POST'));
    },
    useUpdateOvertime<U extends APIRequest.OvertimeReq>() {
      return useSWRMutation<APIResponse.SuccessRes, any, Key, U>(serviceURL, sendRequest<U>('PUT'));
    },
    useDeleteOvertime() {
      return useSWRMutation(serviceURL, sendRequest<APIRequest.SpecificReq>('DELETE'));
    },
  };
}
