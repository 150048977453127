import { isEmpty } from 'lodash';

enum Method {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

type RequestMethod = keyof typeof Method;

type Arg<T> = { id?: string } & T;

export function fetcher<T>(args: [string, any]): Promise<T> {
  return fetch(...args).then(res => res.json());
}

export function sendRequest<T>(method: RequestMethod) {
  return async (key: any, { arg }: { arg: Arg<T> }) => {
    const [url, opt] = key;
    let fetchURL = url;

    if (arg.id) {
      fetchURL += `/${arg.id}`;
      delete arg.id;
    }

    try {
      const res = await fetch(fetchURL, {
        method,
        ...opt,
        ...(!isEmpty(arg) && {
          body: JSON.stringify(arg),
        }),
      });

      if (!res.ok) throw new Error(JSON.stringify(await res.clone().json()));

      return await res.clone().json();
    } catch (err: any) {
      throw new Error(err.message);
    }
  };
}
