import { useGetFetch } from "./helper/fetcher";

import URLs from "data/urls";

import { useToken } from "components/Provider/AuthProvider";
import getQueryString from "libs/getQueryString";

export const useTempSalesService = () => {
  const serviceURL = URLs.tempSales;
  const token = useToken();

  return {
    useListTempSales: (queryOptions, swrOptions, ableToFetch = true) => {
      const query = getQueryString(queryOptions);
      const fetchURL = ableToFetch ? `${serviceURL}${query}` : null;

      return useGetFetch(fetchURL, swrOptions);
    },

    useOneTempSale: (tempSaleId, swrOptions, ableToFetch = true) => {
      const fetchURL = tempSaleId && (ableToFetch ? `${serviceURL}/${tempSaleId}` : null);
      return useGetFetch(fetchURL, swrOptions);
    },
  };
};

export const useSalesService = () => {
  const serviceURL = URLs.sales;
  const token = useToken();

  return {
    useOneSale: (saleId, swrOptions, ableToFetch = true) => {
      const fetchURL = saleId && (ableToFetch ? `${serviceURL}/${saleId}` : null);
      return useGetFetch(fetchURL, swrOptions);
    },
  };
};
