import useSWR from "swr";

import URLs from "data/urls";
import { fetcher } from "service/fetcher/index";
import queryConverter from "service/helper/queryConverter";

export function useDepartmentService() {
  const serviceURL = URLs.department;

  return {
    useListDepartment({ query, swrOpt, isAble = true }: APIRequest.APIArgus<URLSearchParams> = {}) {
      const queryStr = queryConverter(query);
      const fetchURL = isAble ? `${serviceURL}?${queryStr}` : null;
      return useSWR<APIResponse.Pagination<API.Department>>(fetchURL, fetcher, swrOpt);
    },
    useOneDepartment({ query, swrOpt, isAble = true }: APIRequest.APIArgus<string> = {}) {
      const queryStr = queryConverter(query);
      const fetchURL = isAble ? `${serviceURL}/${queryStr}` : null;
      return useSWR<API.Department>(fetchURL, fetcher, swrOpt);
    },
  };
}
